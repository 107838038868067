const Images = {
ali: require('../images/ali.jpeg'),
nopic: require('../images/nopic.jpg'),
choosefile: require('../images/ic_input_file.png'),
input_file: require('../images/ic_photo.png'),
sticker: require('../images/ic_sticker.png'),
send: require('../images/ic_send.png'),
wave_hand: require('../images/14-wave_hand.png'),
lego1: require('../images/lego1.png'),
lego2: require('../images/lego2.png'),
lego3: require('../images/lego3.png'),
lego4: require('../images/lego4.png'),
lego5: require('../images/lego5.png'),
lego6: require('../images/lego6.png'),
mimi1: require('../images/mimi1.gif'),
mimi2: require('../images/mimi2.gif'),
mimi4: require('../images/mimi4.gif'),
mimi5: require('../images/mimi5.gif'),
mimi6: require('../images/mimi6.gif'),
mimi7: require('../images/mimi7.gif'),
mimi8: require('../images/mimi8.gif'),
mimi9: require('../images/mimi9.gif'),
leftarrow: require('../images/left-arrow.svg'),
chica_login: require('../images/chica_login.svg'),
}
export default Images