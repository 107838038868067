import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from 'react-router-dom';
import './App.css';
import Home from './Pages/Home/Home';
import Chat from './Pages/Chat/Chat';
// import ChatList from './Pages/ChatList/ChatList';
// import ChatView from './Pages/ChatView/ChatView';
import Profile from './Pages/Profile/Profile';
import Signup from './Pages/Signup/Signup';
// import Login from './Pages/Login/Login';
import Login2 from './Pages/Login/Login2';
import  firebase from './Services/firebase';
import {toast , ToastContainer} from 'react-toastify';

class App extends Component{

  showToast = (type, message) =>{
    switch (type){
      case 0:
        toast.warning(message)
        break;
        case 1:
          toast.success(message)
          default:
            break;
    }
  }

  render(){
    Notification.requestPermission();
      // return(
      //   <Router>
      //     {/* <ToastContainer
      //     autoClose = {2000}
      //     hideProgressBar = {true}
      //     position = {toast.POSITION.TOP_CENTER}
      //     /> */}
      //     <Switch>
      //       <Route
      //       exact
      //       path ="/"
      //       render = { props => <Login2 {...props}/>}/>

      //       <Route
      //       path = "/login"
      //       render = {props => <Login2 showToast={this.showToast}{...props}/>}
      //       />

      //       <Route
      //       path = "/profile"
      //       render = {props => <Profile showToast={this.showToast}{...props}/>}
      //       />

      //       <Route
      //       path = "/signup"
      //       render = {props => <Signup showToast={this.showToast}{...props}/>}
      //       />

      //       <Route
      //       path = "/chat"
      //       render = {props => <ChatList showToast={this.showToast}{...props}/>}
      //       />

      //       <Route
      //       path = "/chatView"
      //       render = {props => <ChatView {...props}/>}
      //       />

            
      //     </Switch>
      //   </Router>
      // )
    // let width2 = window.screen.width
    // if (width2 < 500){
    //   return  (
    //     <Router>
    //       <Switch>
    //         <Route
    //         exact
    //         path ="/"
    //         render = { props => <Login2 {...props}/>}/>

    //         <Route
    //         path = "/login"
    //         render = {props => <Login2 showToast={this.showToast}{...props}/>}
    //         />

    //         <Route
    //         path = "/profile"
    //         render = {props => <Profile showToast={this.showToast}{...props}/>}
    //         />

    //         <Route
    //         path = "/signup"
    //         render = {props => <Signup showToast={this.showToast}{...props}/>}
    //         />

    //         <Route
    //         path = "/chat"
    //         render = {props => <ChatList showToast={this.showToast}{...props}/>}
    //         />

    //         <Route
    //         path = "/chatView"
    //         render = {props => <ChatView {...props}/>}
    //         />

            
    //       </Switch>
    //     </Router>
    //   )
    // }

    // if (width2 > 500){
        
    
      return  (
        <Router>
          {/* <ToastContainer
          autoClose = {2000}
          hideProgressBar = {true}
          position = {toast.POSITION.TOP_CENTER}
          /> */}
          <Switch>
            <Route
            exact
            path ="/login/:id"
            render = { props => <Login2 {...props}/>}/>

            <Route
            path = "/login"
            render = {props => <Login2 showToast={this.showToast}{...props}/>}
            />

            <Route
            path = "/profile"
            render = {props => <Profile showToast={this.showToast}{...props}/>}
            />

            <Route
            path = "/signup"
            render = {props => <Signup showToast={this.showToast}{...props}/>}
            />

            <Route
            path = "/chat"
            render = {props => <Chat showToast={this.showToast}{...props}/>}
            />

            
          </Switch>
        </Router>
      )
    // }
  }
}
export default App