import React from 'react'
import LoginString from "../Login/LoginStrings";
import firebase from "../../Services/firebase";
import './Chat.css';
import ReactLoading from  'react-loading';
import images from '../../ProjectImages/ProjectImages';
import ChatBox from '../ChatBox/ChatBox';
import WelcomeBoard from '../Welcome/Welcome';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Chat extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            isLoading: true,
            isOpenDialogConfirmLogout: false,
            currentPeerUser:null,
            displayedContacts:[],
            displayedContactswithNotification:[],
            estado: localStorage.getItem(LoginString.state)
        }
        this.currentUserDocumentId = localStorage.getItem(LoginString.FirebaseDocumentId)
        this.currentUserId = localStorage.getItem(LoginString.ID);
        this.currentUserPhoto= localStorage.getItem(LoginString.PhotoURL);
        
        this.currentUserName = localStorage.getItem(LoginString.Name);
        this.currentEnte = localStorage.getItem(LoginString.ente); 
        this.currentUserMessages= [];
        this.searchUsers = [];
        this.displayedContacts =[]
        this.currentUserMessages = [];
        this.notificationMessagesErase = []
        this.getListUsers = this.getListUsers.bind(this);
        this.renderListUser = this.renderListUser.bind(this);
        this.notificationErase = this.notificationErase.bind(this)
        this.changeState = this.changeState.bind(this)
        this.displayStateBox = this.displayStateBox.bind(this)
        this.updateOnline = this.updateOnline.bind(this)
    }
    logout=()=>{
        firebase.auth().signOut()
        this.props.history.push('/')
        localStorage.clear()
    }
    onProfileClick = ()=>{
        this.props.history.push('/profile')
    }
    componentDidMount(){
        let notificationMessages = []
        // firebase.firestore().collection('users').doc(this.currentUserDocumentId).get()
        //     .then((doc)=>{
        //        doc.data().messages.map((item)=>{
        //          this.currentUserMessages.push({
        //             notificationId: item.notificationId,
        //             number: item.number
        //          })
        //        })
        //              this.setState({
        //                displayedContactswithNotification: this.currentUserMessages
        //              })
        //     })
        this.getListUsers();
        setInterval(this.updateOnline, 2000)
        // this.updateOnline()
      }
 
      getListUsers= async() =>{
        // const result =  await firebase.firestore().collection("users").get();
        // if(result.docs.length > 0 ){
        //   let listUsers =[]
        //   listUsers = [...result.docs]
        //   listUsers.forEach((item,index)=>{
        //     this.searchUsers.push(
        //    {   key:index,
        //     documentkey: item.id,
        //     id: item.data().id,
        //     dni: item.data().dni,
        //     fecha_update_state: item.data().fecha_update_state,
        //     name: item.data().name,
        //     messages:item.data().messages,
        //     URL:item.data().URL,
        //     description: item.data().description}
        //     )
        //   })
        //   this.setState({isLoading:false,
        //  })
          
        // }
        let searchUsers = [];
        let time = moment()
            .valueOf()
            .toString()
        
        const result =  await fetch(`https://portal.confluencegroup.es/api/clientes/api.clientes.php?method=get_clientes_ente&timestamp=${time}&ente=${this.currentEnte}`)
        .then(function(response) {
            console.log('response =', response);
            return response.json();
        })
        .then(function(data) {
          
          let listUsers =[]
          listUsers = [...data.clientes]
          listUsers.forEach((item,index)=>{
            searchUsers.push(
           {   key:index,
            documentkey: item.firebaseDocumentId,
            id: item.loginStringId,
            dni: item.username,
            // fecha_update_state: item.fecha_update_state,
            name: item.nombre_completo,
            // messages:item.data().messages,
            URL:item.picture,
            // description: item.data().description
             }
            )
          })
          console.log(searchUsers)
        //   this.setState({isLoading:false,
        //  })
        })
        this.searchUsers = searchUsers;
        this.renderListUser();
      }
     
      getClassnameforUserandNotification = (itemId)=>{
        let number = 0
        let className = ''
      let check = false;
       if(this.state.currentPeerUser && 
        this.state.currentPeerUser.id === itemId){
          
         className =  'viewWrapItemFocused'
         
        }else{
          this.state.displayedContactswithNotification.forEach((item)=>{
            if(item.notificationId.length > 0){
              if(item.notificationId === itemId){
                 check = true
                 number = item.number
              }
            }
          })
            if(check === true){
              className=  'viewWrapItemNotification'
            }else{
              className =  'viewWrapItem'
            }
            
        
    
        }
       return className
      
      }
      notificationErase = (itemId) =>{
        this.state.displayedContactswithNotification.forEach((el)=>{
                           
          if(el.notificationId.length > 0){
            if(el.notificationId != itemId){
          
              this.notificationMessagesErase.push(
                  {
                      notificationId:el.notificationId,
                      number:el.number  
                  }
              )
          }
          }
        
      })
      this.updaterenderlist()
    }
    updaterenderlist = () =>{
      
      // firebase.firestore().collection('users').doc(this.currentUserDocumentId).update(
      //   {messages:this.notificationMessagesErase}
      // )
      // this.setState({
      //   displayedContactswithNotification : this.notificationMessagesErase
      // })
    }
    
    updateOnline = async() => {

      const user_id = this.currentUserId
      let time = new Date().getTime()

      await fetch(`https://portal.confluencegroup.es/api/clientes/api.clientes.php?method=get_clientes&timestamp=${time}&ente=${this.currentEnte}`)
      .then(function(response) {
          // console.log('response =', response);
          return response.json();
      })
      .then(function(data) {
          // console.log(data);
          
          data.forEach((item,index) => {
            if(item.id != user_id){
              /*const timeDiff = Math.round(
                (new Date().getTime() - item.fecha_online) / 60000 * 60
              );*/
              const client_id = `user-${item.id}`

              //console.log(timeDiff)
                  
              if(item.timeDiff <= 5 && document.getElementById(client_id).classList.contains('circle-state-offline')){
                document.getElementById(client_id).classList.remove("circle-state-offline")
                document.getElementById(client_id).classList.add("circle-state-online")
              }else if(item.timeDiff > 5 && document.getElementById(client_id).classList.contains('circle-state-online')){
                document.getElementById(client_id).classList.remove("circle-state-online")
                document.getElementById(client_id).classList.add("circle-state-offline")
              }
            }
          })
      })
      .catch(function(err) {
          console.error(err);
      });

      /*const result =  await firebase.firestore().collection("users").get();
        if(result.docs.length > 0 ){
          let listUsers =[]
          listUsers = [...result.docs]
          listUsers.forEach((item,index)=>{
            if(item.data().id != this.currentUserId){
              const timeDiff = Math.round(
                (new Date().getTime() - item.data().fecha_update_state) / 60000 * 60
              );
              const user_id = `user-${item.data().id}`
              
              console.log(timeDiff)
                  
              if(timeDiff <= 5 && document.getElementById(user_id).classList.contains('circle-state-offline')){
                document.getElementById(user_id).classList.remove("circle-state-offline")
                document.getElementById(user_id).classList.add("circle-state-online")
              }else if(timeDiff > 5 && document.getElementById(user_id).classList.contains('circle-state-online')){
                document.getElementById(user_id).classList.remove("circle-state-online")
                document.getElementById(user_id).classList.add("circle-state-offline")
              }
            }
          })          
        }*/
    //   if(this.searchUsers.length > 0){
    //     let viewListUser = []
    //     let classname = ''
        
    //     this.searchUsers.map((item)=>{   
    //       if(item.id != this.currentUserId){
    //         const timeDiff = Math.round(
    //           (new Date().getTime() - item.fecha_update_state) / 60000 * 60
    //         );
    //         const user_id = `user-${item.id}`
            
    //         console.log(timeDiff)
                
    //         if(timeDiff <= 5 && document.getElementById(user_id).classList.contains('circle-state-offline')){
    //           document.getElementById(user_id).classList.remove("circle-state-offline")
    //           document.getElementById(user_id).classList.add("circle-state-online")
    //         }else if(timeDiff > 5 && document.getElementById(user_id).classList.contains('circle-state-online')){
    //           document.getElementById(user_id).classList.remove("circle-state-online")
    //           document.getElementById(user_id).classList.add("circle-state-offline")
    //         }
    //       }
          
    //     })
    // }
  }
    renderListUser =() =>{
        if(this.searchUsers.length > 0){
          let viewListUser = []
          let classname = ''
          
          this.searchUsers.map((item)=>{   
            if(item.id != this.currentUserId){
              const timeDiff = Math.round(
                (new Date().getTime() - item.fecha_update_state) / 60000 * 60
              );
              const user_id = `user-${item.id}`
              let class_user = 'circle-state-offline'
              console.log(timeDiff)

              if(timeDiff <= 5){
                class_user = 'circle-state-online'
              }else{
                class_user = 'circle-state-offline'
              }

              classname = this.getClassnameforUserandNotification(item.id)
              let url = `https://confluencegroup.es/media/${item.URL}`;
              viewListUser.push(
                
                <button 
             
                id={item.key}
    
                className={classname}
             
                onClick={() => {
                                // this.notificationErase(item.id)
                                this.setState({currentPeerUser: item,
                                displayedContactswithNotification: this.notificationMessagesErase})
                              //   document.getElementById(item.key).style.backgroundColor = "#2c3e50"
                              //  if (document.getElementById(item.key)){
                              //   document.getElementById(item.key).style.color = '#fff'
                              //  }
                            }}
                >
                   <img
                        className="viewAvatarItem"
                        src="https://portal.confluencegroup.es/img/fotos/chica_formulario.png"
                        alt=""
                        placeholder={images.emptyphoto}
                    />
    
                <div className="viewWrapContentItem">
                <span id={user_id} class={class_user}></span><span className="textItem">{item.name} - {item.dni}</span>
                </div>     
                  { classname === 'viewWrapItemNotification' ?
                  <div className='notificationpragraph'>
                    <p id={item.key} className="newmessages">Nuevos mensajes</p>
                  </div>:null}
                </button>  
              )
            }
            
          })
          this.setState({
            displayedContacts:viewListUser
          });
         
        }else{
          console.log("No user is present")
        }
      }
      changeState = (event) => {
        console.log(localStorage.getItem(LoginString.state))
        let newInfo
        let state
        let estado_online
        let timestamp = moment()
            .valueOf()
            .toString()

        if(event.currentTarget.id == 'online'){
          estado_online = 1
          newInfo = {
            state: 1
          }
        }
        else{
          estado_online = 0
          newInfo = {
            state: 0
          }
        }
        // firebase.firestore()
        //     .collection('users')
        //     .doc(localStorage.getItem(LoginString.FirebaseDocumentId))
        //     .update(newInfo)
        //     .then(data => {
        //         localStorage.setItem(LoginString.state, estado_online)
        //         this.setState({estado: estado_online})
        //         document.getElementById('box-estados').classList.add("noVisible");
        //     })
        
        fetch(`https://portal.confluencegroup.es/api/clientes/api.clientes.php?method=update_state_ente&user_id=${this.currentUserId}&state=${estado_online}&fecha=${timestamp}`)
        .then(function(response) {
            console.log('response =', response);
            return response.json();
        })
        .then(function(data) {
          localStorage.setItem(LoginString.state, estado_online)
          
          document.getElementById('box-estados').classList.add("noVisible");
        })

        this.setState({estado: estado_online})
      }
      searchHandler =(event)=>{
        let searchQuery = event.target.value.toLowerCase(),
        displayedContacts = this.searchUsers.filter((el)=>{
          let SearchValue = el.name.toLowerCase();
          return SearchValue.indexOf(searchQuery) !== -1;
        })
        this.displayedContacts = displayedContacts
        this.displaySearchedContacts()
      }
      displayStateBox = (event) => {
        if(document.getElementById('box-estados').classList.contains('noVisible'))
          document.getElementById('box-estados').classList.remove("noVisible");
        else
          document.getElementById('box-estados').classList.add("noVisible");
      }
      displaySearchedContacts=()=>{
        if(this.searchUsers.length > 0){
          let viewListUser = []
          let classname = ''
          this.displayedContacts.map((item)=>{   
            if(item.id != this.currentUserId){

              const timeDiff = Math.round(
                (new Date().getTime() - item.fecha_update_state) / 60000 * 60
              );
              const user_id = `user-${item.id}`
              let class_user = 'circle-state-offline'
              console.log(timeDiff)

              if(timeDiff <= 5){
                class_user = 'circle-state-online'
              }else{
                class_user = 'circle-state-offline'
              }

              classname = this.getClassnameforUserandNotification(item.id)
              viewListUser.push(
                
                <button 
             
                id={item.key}
    
                className={classname}
             
                onClick={() => {
                                this.notificationErase(item.id)
                                this.setState({currentPeerUser: item,
                                displayedContactswithNotification: this.notificationMessagesErase})
                              //   document.getElementById(item.key).style.backgroundColor = "#fff"
                              //  if (document.getElementById(item.key)){
                              //   document.getElementById(item.key).style.color = '#fff'
                              //  }
                            }}
                >
                   <img
                        className="viewAvatarItem"
                        src={item.URL}
                        alt=""
                        placeholder={images.emptyphoto}
                    />
    
                <div className="viewWrapContentItem">
                  <span id={user_id} class={class_user}></span><span className="textItem">{item.name} - {item.dni}</span>
                </div>     
                  { classname === 'viewWrapItemNotification' ?
                  <div className='notificationpragraph'>
                    <p id={item.key} className="newmessages">Nuevos mensajes</p>
                  </div>:null}
                </button>  
              )
            }
            
          })
          this.setState({
            displayedContacts:viewListUser
          });
         
        }else{
          console.log("No user is present")
        }
      }
    render(){
      console.log()
        return(
            <div className="root">
                <div className="body">
                    <div className="viewListUser">
                        <div className="profileviewleftside">
                            <img
                            className="ProfilePicture"
                            alt=""
                            src={this.currentUserPhoto}
                            // onClick={this.onProfileClick}
                            />
                            <p className="leftsidename" onClick={this.displayStateBox}>{this.currentUserName}</p>
                            {this.state.estado == 1 ? (
                              <span className="circle-state-online"></span>
                            ):(
                              <span className="circle-state-offline"></span>
                            )}
                            
                            <div id="box-estados" className ="box-estados noVisible">
                              <ul className="list-estados">
                                <li id="online" onClick={this.changeState}><span className="circle-state-active" ></span><span>Conectado</span></li>
                                <li id="offline" onClick={this.changeState} ><span className="circle-state-inactive" ></span><span>Desconectado</span></li>
                              </ul>
                            </div>
                            {/* <button className="Logout" onClick = {this.logout}>Logout</button> */}
                        </div>
                        <div className="rootsearchbar">
                          <div className="input-container">
                            <i className="fa fa-search icon"></i>
                            <input className="input-field"
                            type="text"
                            onChange={this.searchHandler}
                            placeholder="Buscar"
                            />
                          </div>
                        </div>
                        {this.state.displayedContacts}
                    </div>
                    <div className="viewBoard">
                      {this.state.currentPeerUser ? (
                        <ChatBox currentPeerUser = {this.state.currentPeerUser}
                        showToast={this.props.showToast}
                        />):(<WelcomeBoard 
                        currentUserName={this.currentUserName}
                        currentUserPhoto={this.currentUserPhoto}/>
                      )}
                    </div>
                </div>
               
            </div>
        )
    }
}