const LoginString = {
    ID: 'id',
    PhotoURL: 'photoUrl',
    Name: 'nickname',
    Email: 'email',
    Password: 'image/',
    DOC:'added',
    Description:'mydescription',
    FirebaseDocumentId:'docid',
    UPLOAD_CHANGED: 'state_changed',
    PREFIX_IMAGE: 'image/',
    CurrentUserdocKey:'',
    currentPeerUser:'',
    dni:'',
    state:'',
    ente:''
}
export default LoginString