import React, {Component} from 'react';
import {Link,useParams} from 'react-router-dom';
import firebase from '../../Services/firebase';

import LoginString from '../Login/LoginStrings';
import './Login.css';
import './Login2.css';
import {Card} from 'react-bootstrap';
import images from '../../ProjectImages/ProjectImages';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';

export default class Login extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            isLoading : true,
            email: "",
            password: ""
        }
    this.handleChange =this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmit2 = this.handleSubmit2.bind(this)
    }
    handleChange(event) {
        this.setState({
          [event.target.name]: event.target.value
        });
      }
    componentDidMount(){
        this.handleSubmit()
     
        // localStorage.setItem(LoginString.FirebaseDocumentId, '{{ request.user.firebaseDocumentId }}');
        // localStorage.setItem(LoginString.ID, '{{ request.user.loginStringId }}');
        // localStorage.setItem(LoginString.Name, '{{ request.user.nombre_completo }}')
        // // localStorage.setItem(LoginString.Email, currentdata.email)
        // // localStorage.setItem(LoginString.Password, currentdata.password)
        // localStorage.setItem(LoginString.PhotoURL, '{{ request.user.picture }}')
        // // localStorage.setItem(LoginString.Description, currentdata.description)
        // // console.log(localStorage.setItem(LoginString.ID))
        // // console.log(currentdata.id)
        // // console.log(doc.id)
        // user_id = '{{ request.user.loginStringId }}';

        // this.props.history.push('./chat')
    }
    
    async handleSubmit2(){
        


        
    }

    async handleSubmit(){
        const { match: { params } } = this.props;
        console.log(params.id)
        await fetch(`https://portal.confluencegroup.es/api/clientes/api.clientes.php?method=login_ente&user_id=${params.id}`)
        .then(function(response) {
            console.log('response =', response);
            return response.json();
        })
        .then(function(data) {
            localStorage.setItem(LoginString.FirebaseDocumentId, data.ente.firebaseDocumentId );
            localStorage.setItem(LoginString.ID, data.ente.loginStringId);
            localStorage.setItem(LoginString.Name, data.ente.nombre)
            // localStorage.setItem(LoginString.Email, currentdata.email)
            // localStorage.setItem(LoginString.Password, currentdata.password)
            localStorage.setItem(LoginString.PhotoURL, data.ente.foto_web_cliente)
            localStorage.setItem(LoginString.ente, data.ente.id_enteebroker)
            // localStorage.setItem(LoginString.Description, currentdata.description)
            // console.log(localStorage.setItem(LoginString.ID))
            // console.log(currentdata.id)
            // console.log(doc.id)
            console.log(localStorage.getItem(LoginString.FirebaseDocumentId))
        })
        console.log('hola')
        this.props.history.push('/chat')
        // console.log(this.state.email)
        // console.log(this.state.password)
        // return
        // await firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
        // .then(async result =>{
        //     let user = result.user;
        //     if(user){
        //         await firebase.firestore().collection('users')
        //         .where('id' , "==", user.uid)
        //         .get()
        //         .then(function(querySnapshot){
        //             querySnapshot.forEach(function(doc){
        //                 const currentdata = doc.data();
        //                 localStorage.setItem(LoginString.FirebaseDocumentId, doc.id);
        //                 localStorage.setItem(LoginString.ID, currentdata.id);
        //                 localStorage.setItem(LoginString.Name, currentdata.name)
        //                 localStorage.setItem(LoginString.Email, currentdata.email)
        //                 localStorage.setItem(LoginString.Password, currentdata.password)
        //                 localStorage.setItem(LoginString.PhotoURL, currentdata.URL)
        //                 localStorage.setItem(LoginString.Description, currentdata.description)
        //                 localStorage.setItem(LoginString.dni, currentdata.dni)
        //                 localStorage.setItem(LoginString.state, doc.state)
        //             })
        //         })
        //     }
        //     this.props.history.push('/chat')
        // }).catch(function(error){
        //     document.getElementById('error-login').innerHTML="Usuario o contraseña incorrectos"
        // })
    }
    render(){
        const  paper= {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: '10px',
            paddingRight: '10px'
            
          }
          const rightcomponent={
            boxShadow: "0 80px 80px #808888",
            backgroundColor:'smokegrey',
          }
        
          const root={
            height: '100vh',
            background: "linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%)",
       
            marginBottom:'50px'
          }
          const Signinsee = {
           
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color:'White',
            marginBottom: '20px',
            backgroundColor:'#1ebea5',
            width:'100%',
            boxShadow:" 0 5px 5px #808888",
            height: "10rem",
            paddingTop:"48px",
            opacity:"0.5",
            borderBottom:'5px solid green',
          
      
          }
          const form = {
            width: '100%', // Fix IE 11 issue.
            marginTop: '50px'
          }
          const avatar= {
            backgroundColor: 'green',
          }
        return(


            <div class="login-container">
                <div class="container">
                    <img src={images.chica_login} class="img-container-login" />
                    <div class="div-login-text">
                        <h2 class="welcome-h2">Bienvenid@</h2>
                        <h2 class="welcome-text">¡Hola! Introduce tu nombre de usuario y contraseña para iniciar sesión</h2>
                    </div>
            
                    <form onSubmit={this.handleSubmit} action="#">
                
                        <div class="form-group">
                            <input 
                                id="email"
                                class="form-control-text" 
                                type="text" 
                                placeholder="NIF/CIF" 
                                name="email" 
                                onChange={this.handleChange} />
                        </div>
                
                        <div class="form-group">
                            <input class="form-control-text" id="password" type="password" placeholder="Contraseña" name="password" onChange={this.handleChange} />
                        </div>
                        <div id="error-login"></div>
                        <button class="btn btn-primary btn-login" type="submit">Acceder <i class="fas fa-arrow-right"></i></button>
                
                    </form>
                </div>
            </div>
        )
    }
}